import { validateAll, extend } from 'indicative/validator'
import messages from '../lang/validation'
import { unique } from './../lib/customvalidation';
import { config } from '../config'
import Axios from 'axios'

export default class UserServices {
    async getAllUsers(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-users/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getUserDetail(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-users/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deleteUser(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-users/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addUser(data, token) {
        extend('unique', unique);
        const rules = {
            firstname: "required",
            lastname: "required",
            userroleID: "required"
        }
        if (data.option === "add") {
            rules['email'] = `required|email|unique:email,Users,${token}`;
            rules['username'] = `required|unique:username,Users,${token}`;
        }
        else {
            rules['email'] = `required|email|unique:email,Users,${token},${data.id}`;
            rules['username'] = `required|unique:username,Users,${token},${data.id}`;
        }
        try {
            await validateAll(data, rules, messages)
            const { firstname, lastname, username, email, superAdmin, userroleID, resetPassword, option, id } = data;
            let response;
            if (option === "add") {
                response = await Axios.post(
                    `${config.API_URL}/manage-users/add`,
                    { firstname, lastname, username, email, userroleID, superAdmin },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            } else {
                response = await Axios.patch(
                    `${config.API_URL}/manage-users/edit`,
                    { firstname, lastname, username, email, userroleID, superAdmin, resetPassword, id },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            }
            if ('errors' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            const tmp_errors = {};
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
