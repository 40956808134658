import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import PhaseServices from '../../../services/PhaseServices';
import Label from '../../Basecompoents/Label';
import { AccessDeniedUrl } from '../../../hooks';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Detail Program Phase",
            phaseName: "",
            phaseDescription: "",
        };

    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const id = await this.props.location.pathname.split('/').pop();
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const PhaseService = new PhaseServices();
            const records = await PhaseService.getDetailPhase(user.token, id);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ ...data });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);

        }
    }
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Program Phase Details"
                Addbtn={true}
                url={`/manage-program-phase/view`}
                Addbtntext="Back">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Phase Name:" /> <br />
                                        {this.state.phaseName}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Phase Description:" /> <br />
                                        {this.state.phaseDescription}
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </ContentWrapper >
        );
    }
}

export default Detail;