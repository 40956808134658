export default class AuthorizedPermissionServices {
    async getMenuPermission() {
        const UserPermissions = JSON.parse(localStorage.getItem('user')).loggedInUser.UserPermission;
        const __temp = {};
        for (let key in UserPermissions) {
            __temp[key] = {};
            __temp[key]['menu'] = UserPermissions[key]['menu'];
        }
        return __temp;
    }
    async getPermissions(location, userDetails) {
        const { pathname } = location
        const { UserPermission, isAdmin } = userDetails.loggedInUser
        const accessPermission = pathname.split("/").slice(1)[0];
        const getUserPermission = UserPermission[accessPermission];
        return {
            edit: (getUserPermission != undefined && getUserPermission.edit) || isAdmin,
            add: (getUserPermission != undefined && getUserPermission.add) || isAdmin,
            detail: (getUserPermission != undefined && getUserPermission.detail) || isAdmin,
            delete: (getUserPermission != undefined && getUserPermission.delete) || isAdmin,
            view: (getUserPermission != undefined && getUserPermission.view) || isAdmin,
            menu: (getUserPermission != undefined && getUserPermission.menu) || isAdmin,

        }
    }
}
