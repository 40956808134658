export default {
    email: 'Email',
    password: 'Password',
    name: 'Name',
    companyName: 'Company Name',
    companySite: 'Company Website',
    workEmail: 'Work Email Address',
    workPhone: 'Work Phone Number',
    workArrangement: 'Work Arrangement',
    jobTitle: 'Job Title',
    requiredSkills: 'Required Skills',
    MinimumEducation: 'Minimum Education',
    applyByDate: 'Apply By Date',
    jobDescription: 'Job Description',
    phaseName: 'Phase Name',
    phaseDescription: 'Phase Description',
    workshopDescription: "Workshop Description",
    workshopHours: "Workshop Hours",
    workshopName: "Workshop Name",
    programPhaseID: "Program Phase",
    permission: "Menu/Permission",
    firstname: "First Name",
    lastname: "Last Name",
    username: "Username",
    userrole: "Role",
    rolepermissions: "Role Permission",
    userroleID: "User Role",
    slug: "Slug",
    focName: "Name",
    focImage: "Image",
    focLink: "Website Link",
    HCName: "Name",
    HCImage: "Image",
    HCLink: "Website Link",
    image: "Image",
    description: "Description",
    quote: "Quote",
    designation: "Designation",
    subtitle: "Subtitle",
    phoneNumber: "Phone Number",
    linkedin: "LinkedIn",
    facebook: "Facebook",
    twitter: "twitter",
    Project_Name: 'Project Name',
    Project_url: 'Project URL',
    Project_Description: 'Project Description',
    Project_Image: 'Project Image',
    Category_name: 'Category Name',
    Blog_title: 'Title',
    Blog_slug: 'Slug',
    Blog_content: "Content",
    Blog_author_id: "Author",
    Blog_Image: "Image",
    Blog_category_id: "Category",
    Author_name: "Author Name",
}