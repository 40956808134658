import { validateAll, extend } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import { requiredWithoutField } from '../lib/customvalidation';
import Axios from 'axios'

export default class ProjectShowcaseServices {

    async getAllProjectShowcase(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-project-showcase/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailProjectShowcase(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-project-showcase/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deleteProjectShowcase(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-project-showcase/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addProjectShowcase(data, token) {
        const NotAllow = ["Project_Image", "errors", "success", "option", "title", "createdAt", "deletedAt", "updatedAt"];
        const headers = {
            'Authorization': `${token}`,
            'Content-Type': 'multipart/form-data;',
        }
        extend('requiredWithoutField', requiredWithoutField);
        const rules = {
            Project_Name: 'required',
            Project_Image: 'requiredWithoutField:oldProject_Image',
            Project_url: 'url',
        }
        try {
            await validateAll(data, rules, messages)
            const { option } = data;

            let response;
            var formData = new FormData()
            for (let key in data) {
                if (data.option == "add") {
                    if (key == "id") {
                        continue;
                    }
                }
                if (NotAllow.indexOf(key) > -1) {
                    continue;
                }
                formData.set(key, data[key])
            }
            formData.append('Project_Image', data.Project_Image)

            if (option === "add") {
                response = await Axios.post(
                    `${config.API_URL}/manage-project-showcase/add`,
                    formData,
                    {
                        headers
                    })
            } else {
                response = await Axios.patch(
                    `${config.API_URL}/manage-project-showcase/edit`,
                    formData,
                    {
                        headers
                    })
            }
            if ('errors' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            const tmp_errors = {};
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
