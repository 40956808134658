import { config } from '../config'
import Axios from 'axios'

export default class DropdownServices {
    async getPhaseDropdown(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-program-phase/view/asc`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return await this.makingDropdown(response.data, 'phaseName');

        }
        catch (error) {
            return error;
        }
    }
    async getPhaseDropdownSA(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-program-phase-SA/view/asc`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            console.log(response.data , "Outer");
            if ('status' in response.data) {
                console.log(response.data , "Inner");
                throw response.data;
            }
            return await this.makingDropdown(response.data, 'phaseName');

        }
        catch (error) {
            console.log(error , "ERROR");
            return error;
        }
    }
    async getCategoryDropdown(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-blog-category/view/asc`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return await this.makingDropdown(response.data, 'Category_name');

        }
        catch (error) {
            return error;
        }
    }
    async getAuthorDropdown(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-blog-author/view/asc`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return await this.makingDropdown(response.data, 'Author_name');

        }
        catch (error) {
            return error;
        }
    }
    async getUserRoleDropdown(token, viewAll) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-userrole/view/asc/${viewAll}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return await this.makingDropdown(response.data, 'userrole');

        }
        catch (error) {
            return error;
        }
    }
    async getUserRoleDropdownExclude(token, exlude = "") {
        try {
            const response = await Axios.post(`${config.API_URL}/manage-userrole/exclude`, { exlude: exlude }, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return await this.makingDropdown(response.data, 'userrole');

        }
        catch (error) {
            return error;
        }
    }
    // async getUserRoleDropdown(token, exlude = "") {
    //     try {
    //         const response = await Axios.post(`${config.API_URL}/manage-userrole/exclude`, { exlude: exlude }, {
    //             headers: {
    //                 'Authorization': `${token}`
    //             }
    //         })
    //         if ('status' in response.data) {
    //             throw response.data;
    //         }
    //         return await this.makingDropdown(response.data, 'userrole');

    //     }
    //     catch (error) {
    //         return error;
    //     }
    // }
    async makingDropdown(records, columnName) {
        return records.map((record) => {
            return {
                ['value']: record.id,
                ['text']: record[columnName],
            }
        })
    }
}
