import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import ProjectShowcaseServices from '../../../services/ProjectShowcaseServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import Textarea from '../../Basecompoents/Textarea';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { AccessDeniedUrl } from '../../../hooks';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Project_Name: "",
            Project_Description: "",
            Project_url: "",
            Project_Image: "",
            oldProject_Image: "",
            option: 'add',
            id: '',
            errors: "",
            success: "",
            title: "Add"
        };

    }
    onChangeEvent = (event) => {
        if (event.target.name == "Project_Image") {
            if (event.target.files.length > 0) {
                if (event.target.files[0].size <= 10485760) {
                    let ext = event.target.files[0].name.split('.').pop().toLowerCase();
                    let ext_array = ['jpg', 'jpeg', 'png'];
                    if (ext_array.indexOf(ext) > -1) {
                        this.setState({
                            [event.target.name]: event.target.files[0],
                        })
                        document.querySelector(".custom-file-label").textContent = event.target.files[0].name;
                        let errors = {};
                        this.setState({ errors })
                    }
                    else {
                        let errors = {
                            Project_Image: 'File extension is not valid.'
                        }
                        this.setState({ errors })
                    }
                }
                else {
                    let errors = {
                        Project_Image: 'File size is too big.'
                    }
                    this.setState({ errors })
                }
            }

        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const pathname = this.props.location.pathname.split('/')
        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {

                const user = JSON.parse(localStorage.getItem('user'));
                const ProjectShowcaseService = new ProjectShowcaseServices();
                const records = await ProjectShowcaseService.getDetailProjectShowcase(user.token, id);
                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const data = records.data;
                    data['oldProject_Image'] = data.Project_Image;
                    data.Project_Image = "";
                    this.setState({ ...data, id, option, title: 'Edit' });
                }
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        }
    }
    onSubmithandler = async (event) => {
        const ProjectShowcaseService = new ProjectShowcaseServices();
        event.preventDefault();
        try {
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await ProjectShowcaseService.addProjectShowcase(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                phaseName: "",
                phaseDescription: "",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-project-showcase/view")

            }
        } catch (errors) {
            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} Project`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Project Name: *" />
                                        <Input value={this.state.Project_Name} onChange={this.onChangeEvent} name="Project_Name" className="form-control" type="text" />
                                        {this.state.errors.Project_Name && (
                                            <small className="text-danger">
                                                {this.state.errors.Project_Name}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Project URL:" />
                                        <Input value={this.state.Project_url} onChange={this.onChangeEvent} name="Project_url" className="form-control" type="text" />
                                        {this.state.errors.Project_url && (
                                            <small className="text-danger">
                                                {this.state.errors.Project_url}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Project Description:" />
                                        <Textarea text={this.state.Project_Description} onChange={this.onChangeEvent} name="Project_Description" className="form-control" />
                                        {this.state.errors.Project_Description && (
                                            <small className="text-danger">
                                                {this.state.errors.Project_Description}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Project Image: * " for="InputFile" />
                                        <small className="text-danger"> Max file size : 10MB | allowed file type : jpg,jpeg,png</small>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <Input className="custom-file-input" onChange={this.onChangeEvent} accept="image/jpeg,image/png" name="Project_Image" type="file" />
                                                <Label text="Image" for="InputFile" className="custom-file-label" />
                                            </div>
                                        </div>
                                        {this.state.errors.Project_Image && (
                                            <small className="text-danger">
                                                {this.state.errors.Project_Image}
                                            </small>
                                        )}
                                        {this.state.option == "edit" ?
                                            <Link to={`${config.UploadDir}${this.state.oldProject_Image}`} target="_blank" >{`${config.UploadDir}${this.state.oldProject_Image}`}</Link> : ''
                                        }
                                    </Formgroup>
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-project-showcase/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;