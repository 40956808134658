import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

const SideMenu = ({ userDetails, location, menuItems, MenuPermission, ...restprops }) => {
    useEffect(() => {
        const elements = document.querySelectorAll(".nav-item");
        elements.forEach((element) => {
            const url = location.pathname.split("/");
            url.shift();
            const Anchor = element.querySelector("a")
            const href = Anchor.href;
            var hasTreen = document.querySelector('.has-treeview');
            if (hasTreen != null) {
                hasTreen.className = `nav-item has-treeview`;
            }
            if (url[0] !== "") {
                if (href.indexOf(`${url[0]}/`) > -1) {
                    var TreeView = Anchor.parentElement.parentElement.previousSibling;
                    if (TreeView) {
                        TreeView.className += ` active`
                    }
                    Anchor.className += ` active`;
                } else {

                    Anchor.className = `nav-link`;
                }
            }
        })
    });

    return (

        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <Link to="/" className="brand-link">
                <img src={`${process.env.PUBLIC_URL}/images/favicon.png`} alt="" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-light">{process.env.REACT_APP_TITLE}</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    {/* <div className="image">
                        <img src={`${process.env.PUBLIC_URL}/assets/dist/img/user2-160x160.jpg`} className="img-circle elevation-2" alt="" />
                    </div> */}
                    <div className="info">
                        <Link to="#" className="d-block">{userDetails.loggedInUser && `${userDetails.loggedInUser.firstname} ${userDetails.loggedInUser.lastname}`}</Link >
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {menuItems &&
                            menuItems.map((menuItem, index) => {
                                return (
                                    // MenuPermission[menuItem.slug] != undefined && MenuPermission[menuItem.slug].menu ?
                                        <li className="nav-item" key={index}>
                                            <Link to={`/${menuItem.slug}/view`} className={`nav-link `}>
                                                <i className="reduce-size2x nav-icon far fa-circle"></i>
                                                <p>{menuItem.permission}</p>
                                            </Link>
                                        </li>
                                        // : ""
                                )
                            })
                        }
                        {
                            userDetails.loggedInUser.isAdmin
                                ?
                                <li className="nav-item has-treeview">
                                    <Link to="/" className="nav-link">
                                        <i className="nav-icon fas fa-cog" />
                                        <p>
                                            Site Tools / Setting
                                    <i className="fas fa-angle-left right" />
                                        </p>
                                    </Link >
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/manage-users/view" className="nav-link">
                                                <i className="reduce-size2x far fa-circle nav-icon" />
                                                <p>Manage Users</p>
                                            </Link >
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/manage-permission/view" className="nav-link">
                                                <i className="reduce-size2x far fa-circle nav-icon" />
                                                <p>Manage Menu / Permission</p>
                                            </Link >
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/manage-userrole/view" className="nav-link">
                                                <i className="reduce-size2x far fa-circle nav-icon" />
                                                <p>Manage Role</p>
                                            </Link >
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/manage-userrole-permission/view" className="nav-link">
                                                <i className="reduce-size2x far fa-circle nav-icon" />
                                                <p>Manage Role Permission</p>
                                            </Link >
                                        </li>
                                    </ul>
                                </li>
                                : ''
                        }


                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div >
            {/* /.sidebar */}
        </aside >
    );
}
export default SideMenu;