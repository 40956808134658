import React from 'react';
import { Link } from "react-router-dom";
const Navbar = ({ userDetails, history, ...restProps }) => {
    return (

        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link" data-widget="pushmenu" to="#"><i className="fas fa-bars" /></Link>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Notifications Dropdown Menu */}
                <li className="nav-item dropdown">
                    <Link className="nav-link" data-toggle="dropdown" to="#">
                        <i className="far fa-user mr-2 fa-2x" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <span className="dropdown-item dropdown-header">{userDetails.loggedInUser && `${userDetails.loggedInUser.firstname} ${userDetails.loggedInUser.lastname}`}</span>
                        <div className="row">
                            <div className="col-md-12 px-3 py-2 text-center">
                                <div className="float-left">
                                    <Link to="/myprofile" className="btn btn-default">My Profile</Link>
                                </div>
                                <div className="float-right">
                                    <Link to="#" className="btn btn-primary" onClick={() => {
                                        localStorage.removeItem('user');
                                        history.push('/login');
                                    }}>Logout</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;