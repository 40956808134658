import { validateAll } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import Axios from 'axios'

export default class PhaseDetailServices {
    async getAllPhaseDetails(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-phase-details/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailPhaseDetails(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-phase-details/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deletePhaseDetails(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-phase-details/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addPhaseDetails(data, token) {
        const rules = {
            workshopName: 'required',
            workshopDescription: 'required',
            workshopHours: 'required|number',
            programPhaseID: 'required',
        }
        try {
            await validateAll(data, rules, messages)
            const { workshopName, workshopHours, workshopDescription, programPhaseID, option, id } = data;
            let response;
            if (option === "add") {
                response = await Axios.post(
                    `${config.API_URL}/manage-phase-details/add`,
                    { workshopName, workshopHours, programPhaseID, workshopDescription },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            } else {
                response = await Axios.patch(
                    `${config.API_URL}/manage-phase-details/edit`,
                    { workshopName, workshopHours, programPhaseID, workshopDescription, id },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            }
            if ('errors' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            const tmp_errors = {};
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
