import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { useTable } from 'react-table'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
const columns = [
    {
        Header: 'Menu Name',
        accessor: 'permission',
    },
];
const DraggedTable = ({ data, DragnDropUpdate }) => {
    const [records, setRecords] = React.useState(data)
    const getRowId = React.useCallback(row => {
        return row.id
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        data: records,
        columns,
        getRowId,
    })
    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = records[dragIndex]
        setRecords(
            update(records, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        )
    }
    const updateData = async () => {
        await DragnDropUpdate(records)
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <table className="table table-striped table-hover">
                <thead  {...getTableProps()}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <th>Order</th>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody  {...getTableBodyProps()}>
                    {rows.map(
                        (row, index) =>
                            prepareRow(row) || (
                                <Row
                                    index={index}
                                    row={row}
                                    moveRow={moveRow}
                                    updateData={updateData}
                                    {...row.getRowProps()}
                                />
                            )
                    )}
                </tbody>
            </table>
        </DndProvider>
    )
}

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, moveRow, updateData }) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        drop(item, monitor) {
            updateData();
        },
        hover(item, monitor) {
            if (!dropRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveRow(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DND_ITEM_TYPE, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)
    return (
        <tr ref={dropRef} style={{ opacity }}>
            <td ref={dragRef}><FontAwesomeIcon icon={faArrowsAlt} /></td>
            {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
        </tr>
    )
}

export default DraggedTable;
