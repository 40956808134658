import React from 'react';
import { Link } from "react-router-dom";
const ContentWrapper = ({ children, pageHeading, Addbtn, url, Addbtntext, location, userDetails, permissionBtn }) => {
    const { pathname } = location
    const { UserPermission, isAdmin } = userDetails.loggedInUser
    const accessPermission = pathname.split("/").slice(1)[0];
    const getUserPermission = UserPermission[accessPermission];
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">{pageHeading}</h1>
                        </div>
                        {
                            (getUserPermission != undefined && getUserPermission.add) || isAdmin ?
                                Addbtn &&
                                <div className="col-sm-6">
                                    <Link to={url} className="btn btn-warning float-right" >{Addbtntext}</Link>
                                    {
                                        (getUserPermission != undefined && getUserPermission.add) || isAdmin ?
                                            permissionBtn &&
                                            <Link to='/manage-permission/menu-position' className="btn btn-success float-right mx-2" >Manage Menu Position</Link>
                                            : ''
                                    }
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>
            <section className="content">
                {children}
            </section>
        </div>
    );
}

export default ContentWrapper; 