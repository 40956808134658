import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import BlogAuthorServices from '../../../services/BlogAuthorServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import { AccessDeniedUrl } from '../../../hooks';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Author_name: "",
            option: 'add',
            id: '',
            errors: "",
            success: "",
            title: "Add"
        };

    }
    onChangeEvent = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const pathname = this.props.location.pathname.split('/')
        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {

                const user = JSON.parse(localStorage.getItem('user'));
                const BlogAuthorService = new BlogAuthorServices();
                const records = await BlogAuthorService.getDetailBlogAuthor(user.token, id);
                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const data = records.data;
                    this.setState({ ...data, id, option, title: 'Edit' });
                }
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        }
    }
    onSubmithandler = async (event) => {
        const BlogAuthorService = new BlogAuthorServices();
        event.preventDefault();
        try {
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await BlogAuthorService.addBlogAuthor(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                Author_name: "",
                phaseDescription: "",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-blog-author/view")

            }
        } catch (errors) {
            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} Blog Author`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Author: *" />
                                        <Input value={this.state.Author_name} onChange={this.onChangeEvent} name="Author_name" className="form-control" type="text" />
                                        {this.state.errors.Author_name && (
                                            <small className="text-danger">
                                                {this.state.errors.Author_name}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-blog-author/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;