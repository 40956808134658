import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import PermissionServices from '../../../services/PermissionServices';
import { AccessDeniedUrl } from '../../../hooks';
import DraggedTable from '../../Basecompoents/DragableTable';

class FormMenuPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: "",
            option: 'add',
            id: '',
            errors: "",
            success: "",
            menuposition: [],
            title: "Menu Position"
        };

    }
    onChangeEvent = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const PermissionService = new PermissionServices();
            const records = await PermissionService.getMenuPosition(user.token);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ menuposition: data, title: 'Menu Position' });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    async DragnDropUpdate(updateRecords) {
        const user = JSON.parse(localStorage.getItem('user'));
        const PermissionService = new PermissionServices();
        const records = await PermissionService.updateMenuPosition(user.token, updateRecords);
        if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
            throw records
        }
    }

    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} `} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {
                                    this.state.menuposition.length > 0 ?
                                        <DraggedTable data={this.state.menuposition} DragnDropUpdate={this.DragnDropUpdate} />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default FormMenuPosition;