import React from 'react';
const Textarea = (props) => {
    return (
        <textarea
            defaultValue={props.text && props.text}
            name={props.name}
            className={props.className}
            id={props.id && props.id}
            onChange={props.onChange && props.onChange}
        ></textarea>);
}
export default Textarea