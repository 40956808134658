import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import Table from '../../Basecompoents/Table';
import FriendsofCodegirlsServices from '../../../services/FriendsofCodegirlsServices';
import Successmessage from '../../Basecompoents/Successmessage';
import swal from 'sweetalert';
import Deletedmessage from '../../Basecompoents/Deletedmessage'
import ActionButton from '../../Basecompoents/ActionButton';
import Image from '../../Basecompoents/Image';
import { AccessDeniedUrl } from '../../../hooks';

class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Manage Friends of Codegirls",
            successMessage: '',
            deletedRecord: '',
            records: [],
            columns: [{
                dataField: 'focName',
                text: 'Friends of Codegirls',
                sort: true,
            },
            {
                dataField: 'focDescription',
                text: 'Description',
                sort: true,
            },
            {
                dataField: 'focImage',
                text: 'Image',
                formatter: (cellContent, row) => (
                    <Image
                        src={`${row.focImage}`}
                        alt={row.focImage}
                        style={{ width: '100px', height: '100px' }}
                        className="img-thumbnail" />
                )
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: (cellContent, row) => (
                    <ActionButton
                        {...props}
                        editRecord={`/manage-friends-of-codegirls/edit/${row.id}`}
                        deleteRecord={row.id}
                        deleteEvent={this.onClickHandle}
                        detailRecord={`/manage-friends-of-codegirls/detail/${row.id}`}
                    />
                )
            }
            ]
        };

    }
    onClickHandle = async (event) => {
        event.preventDefault();
        const id = event.target.dataset.id;
        swal({
            title: "",
            text: "Do you want to delete?",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const user = JSON.parse(localStorage.getItem('user'));
                        const FriendsofCodegirlsService = new FriendsofCodegirlsServices();
                        const records = await FriendsofCodegirlsService.deleteFriendOfCodegirls(user.token, id);
                        if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                            throw records
                        }
                        if (records.hasOwnProperty("status") && records.status === "success") {
                            const records = await this.getRecords();
                            this.setState({
                                deletedRecord: true,
                                records,
                                successMessage: "",
                            })
                        }
                    }
                    catch (error) {
                        await AccessDeniedUrl(error.status, this.props);
                    }
                }
            });
    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const recordsDetails = JSON.parse(localStorage.getItem('recordsDetails'));
        let successMessage = "";
        if (recordsDetails !== null && recordsDetails === "success") {
            successMessage = recordsDetails;
            localStorage.removeItem('recordsDetails')
        }
        const records = await this.getRecords();
        this.setState({ records, successMessage })
    }
    getRecords = async () => {
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const FriendsofCodegirlsService = new FriendsofCodegirlsServices();
            const records = await FriendsofCodegirlsService.getAllFriendsOfCodegirls(user.token);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            return records;
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        /*e.g. 
           {
           dataField: 'phaseName',
           order: 'desc'
       }*/
        const defaultSorted = [];
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Manage Friends of Codegirls"
                Addbtn={true}
                url={`/manage-friends-of-codegirls/add`}
                Addbtntext="Add Friend of Codegirls">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {
                                this.state.successMessage &&
                                <Successmessage />
                            }
                            {
                                this.state.deletedRecord &&
                                <Deletedmessage />
                            }
                            <Table
                                records={this.state.records}
                                columns={this.state.columns}
                                defaultSortedChange={false}
                                defaultSorted={defaultSorted}
                            />
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default View;