import { validateAll } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import Axios from 'axios'

export default class AuthServices {
    async LoginAuth(data) {
        const rules = {
            email: 'required|email',
            password: 'required',
        }
        try {
            await validateAll(data, rules, messages)
            const { email, password } = data;
            const response = await Axios.post(`${config.API_URL}/get-token`, { email, password })
            if ('errors' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            const tmp_errors = {};
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
