import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import Label from '../../Basecompoents/Label';
import PermissionServices from '../../../services/PermissionServices';
import RolePermissionServices from '../../../services/RolepermissionServices';
import Input from '../../Basecompoents/Input';
import UserRoleServices from '../../../services/UserroleServices';
import { AccessDeniedUrl } from '../../../hooks';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Role Permission Details",
            userrole: "",
            rolepermissions: {},
            permissions: []
        };

    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const id = await this.props.location.pathname.split('/').pop();
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const PermissionService = new PermissionServices();
            const RolepermissionService = new RolePermissionServices();
            const UserroleService = new UserRoleServices();
            const Userrole = await UserroleService.getDetailUserRole(user.token, id);
            const permissions = await PermissionService.getAllPermissionsWithGroup(user.token);
            const records = await RolepermissionService.getDetailRolePermisson(user.token, id);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ ...data, permissions, userrole: Userrole.data.userrole });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Role Permission Detail"
                Addbtn={true}
                url={`/manage-userrole-permission/view`}
                Addbtntext="Back">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Role:" /> <br />
                                        <h4> {this.state.userrole}</h4>
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        {
                                            this.state.permissions.length ?
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Permission</th>
                                                            {
                                                                this.state.permissions[0].Permissiongroups.map((Permissiongroup, index) => {
                                                                    return <th
                                                                        key={index}
                                                                        className="text-center">{Permissiongroup.permissionTypeID.type}</th>
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.permissions.map((permission, index) => {
                                                                return (<tr key={index}>
                                                                    <td>{permission.permission}</td>
                                                                    {permission.Permissiongroups.map((Permissiongroup, index) => {
                                                                        return (<td key={index}>
                                                                            <Input
                                                                                value={Permissiongroup.id}
                                                                                type="checkbox"
                                                                                name={`rolepermissions`}
                                                                                disabled
                                                                                data-permission={`${permission.permission.replace(/\s/g, "-").toLowerCase()}-${Permissiongroup.permissionTypeID.type.replace(/\s/g, "-").toLowerCase()}`}
                                                                                checked={
                                                                                    this.state.rolepermissions[`${permission.permission.replace(/\s/g, "-").toLowerCase()}-${Permissiongroup.permissionTypeID.type.replace(/\s/g, "-").toLowerCase()}`] == Permissiongroup.id ? true : false
                                                                                }
                                                                                className="form-control"
                                                                                onChange={this.onChangeEvent} />
                                                                        </td>)
                                                                    })}
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                : ''
                                        }
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </ContentWrapper >
        );
    }
}

export default Detail;