import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import UserServices from '../../../services/UserServices';
import Formgroup from '../../Basecompoents/Formgroup';
import DropdownServices from '../../../services/DropdownServices';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Select from '../../Basecompoents/Select';
import { AccessDeniedUrl } from '../../../hooks';


class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            userroles: "",
            userroleID: "",
            // superAdmin: "",
            option: 'add',
            id: '',
            errors: "",
            success: "",
            title: "Add"
        };

    }
    onChangeEvent = (event) => {
        if (event.target.name === "superAdmin" || event.target.name === "resetPassword") {
            if (event.target.checked) {
                this.setState({
                    [event.target.name]: event.target.value,
                });
            }
            else {
                this.setState({
                    [event.target.name]: false,
                });
            }
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const pathname = this.props.location.pathname.split('/')
        const user = JSON.parse(localStorage.getItem('user'));
        const DropdownService = new DropdownServices();
        const userroles = await DropdownService.getUserRoleDropdown(user.token, 'view-all');

        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {
                const UserService = new UserServices();
                const records = await UserService.getUserDetail(user.token, id);
                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const data = records.data;
                    this.setState({ ...data, userroles, id, option, title: 'Edit' });
                }
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        } else {
            this.setState({ userroles });
        }
    }
    onSubmithandler = async (event) => {
        const UserService = new UserServices();
        event.preventDefault();
        try {
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await UserService.addUser(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            if (response.hasOwnProperty("status") && response.status === "success") {
                this.setState({
                    firstname: "",
                    lastname: "",
                    username: "",
                    email: "",
                    // superAdmin: "",
                    errors: "",
                    success: response.message,
                });
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-users/view")
            } else {
                swal({
                    title: "",
                    text: response.message,
                    warning: "",
                })
            }
        } catch (errors) {
            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} User`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="First Name: *" />
                                        <Input
                                            value={this.state.firstname}
                                            onChange={this.onChangeEvent}
                                            name="firstname"
                                            className="form-control"
                                            type="text" />
                                        {this.state.errors.firstname && (
                                            <small className="text-danger">
                                                {this.state.errors.firstname}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Last Name: *" />
                                        <Input
                                            value={this.state.lastname}
                                            onChange={this.onChangeEvent}
                                            name="lastname"
                                            className="form-control"
                                            type="text" />
                                        {this.state.errors.lastname && (
                                            <small className="text-danger">
                                                {this.state.errors.lastname}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Username: *" />
                                        <Input
                                            value={this.state.username}
                                            onChange={this.onChangeEvent}
                                            name="username"
                                            className="form-control"
                                            type="text" />
                                        {this.state.errors.username && (
                                            <small className="text-danger">
                                                {this.state.errors.username}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Email: *" />
                                        <Input
                                            disabled={this.state.option == "edit" ? true : false}
                                            value={this.state.email}
                                            onChange={this.onChangeEvent}
                                            name="email"
                                            className="form-control"
                                            type="text" />
                                        {this.state.errors.email && (
                                            <small className="text-danger">
                                                {this.state.errors.email}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Role: *" />
                                        <Select onChange={this.onChangeEvent}
                                            selectedValue={this.state.userroleID}
                                            className="form-control select2"
                                            name="userroleID"
                                            placeholder="Select Role"
                                            options={this.state.userroles} />
                                        {this.state.errors.userroleID && (
                                            <small className="text-danger">
                                                {this.state.errors.userroleID}
                                            </small>
                                        )}
                                    </Formgroup>
                                    {/*    <Formgroup>
                                        <Label text="Super Admin:" className="custom-position-label" for="checkboxPrimary1" />
                                        <div className="icheck-primary d-inline ml-2">
                                            <Input
                                                id="checkboxPrimary1"
                                                checked={this.state.superAdmin == "true" || this.state.superAdmin ? true : false}
                                                onChange={this.onChangeEvent}
                                                name="superAdmin"
                                                type="checkbox"
                                                value={true} />
                                            <Label for="checkboxPrimary1" />
                                        </div>
                                    </Formgroup> */}
                                    {
                                        this.state.option == "edit" ?
                                            <Formgroup>
                                                <Label text="Reset Password:" className="custom-position-label" for="checkboxPrimary2" />
                                                <div className="icheck-primary d-inline ml-2">
                                                    <Input
                                                        id="checkboxPrimary2"
                                                        checked={this.state.resetPassword == "true" || this.state.resetPassword ? true : false}
                                                        onChange={this.onChangeEvent}
                                                        name="resetPassword"
                                                        type="checkbox"
                                                        value="true" />
                                                    <Label for="checkboxPrimary2" />
                                                </div>
                                            </Formgroup>
                                            : ''
                                    }
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-users/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;