import CustomValidationServices from '../services/CustomValidationServices'
import { getValue, skippable } from 'indicative-utils'
const minlength = {
    async: true,

    compile(args) {
        if (args.length < 1) {
            throw new Error('minlength rule needs the minimum length')
        }
        return args
    },

    async validate(data, field, args, config) {
        const fieldValue = getValue(data, field)
        const [length] = args;
        if (skippable(fieldValue, field, config)) {
            return true
        }
        if (Object.keys(fieldValue).length < length) {
            return false
        }
        return true
    }
}
const requiredWithoutField = {
    async: true,

    compile(args) {
        if (args.length < 1) {
            throw new Error('requiredWithoutField rule needs the minimum length')
        }
        return args
    },

    async validate(data, field, args) {

        const fieldValue = getValue(data, field);
        const [alternateFieldName] = args;
        const alternateFieldValue = getValue(data, alternateFieldName);
        if (alternateFieldValue == "") {
            if (fieldValue == "") {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }
}

const unique = {
    async: true,
    compile(args) {
        if (args.length < 3) {
            throw new Error('Unique rule needs the table and column name')
        }
        return args
    },

    async validate(data, field, args, config) {
        const Inputvalue = getValue(data, field);
        const [columnName, tableName, token, id] = args;
        const CustomValidationService = new CustomValidationServices();
        const uniqueResult = await CustomValidationService.uniqueValidation({ tableName, columnName, Inputvalue, id }, token);
        if (skippable(Inputvalue, field, config)) {
            return true
        }
        return uniqueResult
    }
}

export { minlength, unique, requiredWithoutField }