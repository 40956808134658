import React from 'react';
const Input = ({ name, className, type, id, value, onChange, ...restProps }) => {
    return (
        <input
            name={name}
            className={className}
            type={type}
            id={id && id}
            value={value && value}
            onChange={onChange && onChange}
            {...restProps}
        />);
}
export default Input