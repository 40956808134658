import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import ProjectShowcaseServices from '../../../services/ProjectShowcaseServices';
import Label from '../../Basecompoents/Label';
import Image from '../../Basecompoents/Image';
import { Link } from 'react-router-dom';
import { AccessDeniedUrl } from '../../../hooks';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Detail Project Showcase",
            Project_Name: "",
            Project_Description: "",
            Project_url: "",
            Project_Image: "",
        };

    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const id = await this.props.location.pathname.split('/').pop();
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const ProjectShowcaseService = new ProjectShowcaseServices();
            const records = await ProjectShowcaseService.getDetailProjectShowcase(user.token, id);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ ...data });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Project Showcase Details"
                Addbtn={true}
                url={`/manage-project-showcase/view`}
                Addbtntext="Back">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Project Name:" /> <br />
                                        {this.state.Project_Name}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Project Description:" /> <br />
                                        {this.state.Project_Description}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Project URL:" /> <br />
                                        <Link to={this.state.Project_url} target="_blank"> {this.state.Project_url}</Link>
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Image
                                            src={`${this.state.Project_Image}`}
                                            alt={this.state.Project_Image}
                                            style={{ width: '150px', height: '150px' }}
                                            className="img-thumbnail" />
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </ContentWrapper >
        );
    }
}

export default Detail;