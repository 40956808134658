import { validateAll, extend } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import Axios from 'axios'
import { unique } from '../lib/customvalidation'

export default class PermissionServices {
    async getAllPermissions(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-permission/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllMenuItems() {
        try {
            const response = await Axios.get(`${config.API_URL}/menu-items/view`)
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getAllPermissionsWithGroup(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-permission/permission-with-group`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailPermisson(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-permission/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getMenuPosition(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-permission/menu-position/`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async updateMenuPosition(token, data_array) {
        try {
            const response = await Axios.post(
                `${config.API_URL}/manage-permission/update-menu-position/`,
                { data_array },
                {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deletePermission(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-permission/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addPermission(data, token) {
        extend('unique', unique);
        const rules = {
            permission: 'required'
        }
        if (data.option === "add") {
            rules['slug'] = `required|unique:slug,permissions,${token}`;
        }
        else {
            rules['slug'] = `required|unique:slug,permissions,${token},${data.id}`;
        }

        try {
            await validateAll(data, rules, messages)
            const { permission, slug, option, id } = data;
            let response;
            if (option === "add") {
                response = await Axios.post(
                    `${config.API_URL}/manage-permission/add`,
                    { permission, slug },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            } else {
                response = await Axios.patch(
                    `${config.API_URL}/manage-permission/edit`,
                    { permission, slug, id },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            }
            if ('errors' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            const tmp_errors = {};
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
