import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import Table from '../../Basecompoents/Table';
import HireDeveloperServices from '../../../services/HireDeveloperServices';
import Successmessage from '../../Basecompoents/Successmessage';
import swal from 'sweetalert';
import Deletedmessage from '../../Basecompoents/Deletedmessage'
import ActionButton from '../../Basecompoents/ActionButton';
import { AccessDeniedUrl } from '../../../hooks';

class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Manage Hire a Codegirls",
            successMessage: '',
            deletedRecord: '',
            records: [],
            columns: [{
                dataField: 'name',
                text: 'Name',
                sort: true,
            },
            {
                dataField: 'companyName',
                text: 'Company Name',
                sort: true,
            },
            {
                dataField: 'companySite',
                text: 'Company Website',
                sort: true,
            },
            {
                dataField: 'jobTitle',
                text: 'Job Title',
                sort: true,
            },
            {
                dataField: 'applyByDate',
                text: 'Apply By Date',
                sort: true,
            }, {
                dataField: 'action',
                text: 'Action',
                formatter: (cellContent, row) => (
                    <ActionButton
                        {...props}
                        deleteRecord={row.id}
                        deleteEvent={this.onClickHandle}
                        detailRecord={`/manage-hire-a-codegirl/detail/${row.id}`}
                    />
                )
            }
            ]
        };

    }
    onClickHandle = async (event) => {
        event.preventDefault();
        const id = event.target.dataset.id;
        swal({
            title: "",
            text: "Do you want to delete?",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const user = JSON.parse(localStorage.getItem('user'));
                        const HireDeveloper = new HireDeveloperServices();
                        const records = await HireDeveloper.deleteHireDeveloper(user.token, id);
                        if (records.hasOwnProperty("status") && records.status === "TokenExpired") {
                            throw records
                        }
                        if (records.hasOwnProperty("status") && records.status === "success") {
                            const records = await this.getRecords();
                            this.setState({
                                deletedRecord: true,
                                records,
                                successMessage: "",
                            })
                        }
                    }
                    catch (error) {
                        await AccessDeniedUrl(error.status, this.props);
                    }
                }
            });
    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const recordsDetails = JSON.parse(localStorage.getItem('recordsDetails'));
        let successMessage = "";
        if (recordsDetails !== null && recordsDetails === "success") {
            successMessage = recordsDetails;
            localStorage.removeItem('recordsDetails')
        }
        const records = await this.getRecords();
        this.setState({ records, successMessage })
    }
    getRecords = async () => {
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const HireDeveloper = new HireDeveloperServices();
            const records = await HireDeveloper.getHireDeveloper(user.token);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            return records;
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        /*e.g. 
            {
            dataField: 'phaseName',
            order: 'desc'
        }*/
        const defaultSorted = [];
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Manage Hire a Codegirls"
                Addbtn={true}
                url={`/manage-hire-a-codegirl/add`}
                Addbtntext="Add Hire A Codegirls">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {
                                this.state.successMessage &&
                                <Successmessage />
                            }
                            {
                                this.state.deletedRecord &&
                                <Deletedmessage />
                            }
                            <Table
                                records={this.state.records}
                                columns={this.state.columns}
                                defaultSortedChange={false}
                                defaultSorted={defaultSorted}
                            />
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default View;