import React from 'react';
import ContentWrapper from '../../template/ContentWrapper';

const Dashboardpage = (props) => {
    return (
        <ContentWrapper {...props} pageHeading="Welcome to Dashboard">
        </ContentWrapper>
    );
}

export default Dashboardpage;