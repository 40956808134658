import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import HireDeveloperServices from '../../../services/HireDeveloperServices';
import Label from '../../Basecompoents/Label';
import { AccessDeniedUrl } from '../../../hooks';

class HireCodegirlsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Hire a Codegirls",
            name: "",
            companyName: "",
            companySite: "",
            workEmail: "",
            workPhone: "",
            workArrangement: "",
            jobTitle: "",
            requiredSkills: "",
            MinimumEducation: "",
            applyByDate: "",
            jobDescription: "",
        };

    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const id = await this.props.location.pathname.split('/').pop();
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const HireDeveloper = new HireDeveloperServices();
            const records = await HireDeveloper.getDetailHireDeveloper(user.token, id);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ ...data });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Hire a Developer Details"
                Addbtn={true}
                url={`/manage-hire-a-codegirl/View`}
                Addbtntext="Back">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Name:" /> <br />
                                        {this.state.name}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Company Name:" /> <br />
                                        {this.state.companyName}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Company Website:" /> <br />
                                        {this.state.companySite}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Work Email Address:" /> <br />
                                        {this.state.workEmail}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Work Phone Number:" /> <br />
                                        {this.state.workPhone}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Work Arrangement:" /> <br />
                                        {this.state.workArrangement}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Job Title:" /> <br />
                                        {this.state.jobTitle}
                                    </div >
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Required Skills:" /> <br />
                                        {this.state.requiredSkills}
                                    </div >
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Minimum Education:" /> <br />
                                        {this.state.MinimumEducation}
                                    </div >
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Apply By Date:" /> <br />
                                        {this.state.applyByDate}
                                    </div >
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Job Description:" /> <br />
                                        {this.state.jobDescription}
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </ContentWrapper >
        );
    }
}

export default HireCodegirlsDetails;