import React from 'react';
import { config } from '../../../config';
const Image = ({ src, className, id, ...restProps }) => {
    return (
        <div className=""  {...restProps}>
            <img
                alt={src.split("/").pop()}
                src={`${config.UploadDir}${src}`}
                className={className}
            />
        </div>);
}
export default Image