import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import Table from '../../Basecompoents/Table';
import BlogServices from '../../../services/BlogServices';
import Successmessage from '../../Basecompoents/Successmessage';
import swal from 'sweetalert';
import Deletedmessage from '../../Basecompoents/Deletedmessage'
import ActionButton from '../../Basecompoents/ActionButton';
import Image from '../../Basecompoents/Image';
import { AccessDeniedUrl } from '../../../hooks';

class View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Manage Blog",
            successMessage: '',
            deletedRecord: '',
            records: [],
            columns: [{
                dataField: 'Blog_title',
                text: 'Title',
                sort: true,
            },
            {
                dataField: 'Blog_slug',
                text: 'Slug',
                sort: true,
            },
            {
                dataField: 'Blog_content',
                text: 'Content',
                sort: true,
                formatter: (cellContent, row) => (<div dangerouslySetInnerHTML={{ __html: row.Blog_content }}></div>)
            },
            {
                dataField: 'Blog_author_id',
                text: 'Author',
                sort: true,
                formatter: (cellContent, row) => (row.Blog_author_id.Author_name
                )
            },
            {
                dataField: 'Blog_category_id',
                text: 'Category',
                sort: true,
                formatter: (cellContent, row) => (row.Blog_category_id.Category_name
                )
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: (cellContent, row) => (
                    <ActionButton
                        {...props}
                        editRecord={`/manage-blog/edit/${row.id}`}
                        deleteRecord={row.id}
                        deleteEvent={this.onClickHandle}
                        detailRecord={`/manage-blog/detail/${row.id}`}
                    />
                )
            }
            ]
        };

    }

    onClickHandle = async (event) => {
        event.preventDefault();
        const id = event.target.dataset.id;
        swal({
            title: "",
            text: "Do you want to delete?",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    try {
                        const user = JSON.parse(localStorage.getItem('user'));
                        const BlogService = new BlogServices();
                        const records = await BlogService.deleteBlog(user.token, id);
                        if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                            throw records
                        }
                        if (records.hasOwnProperty("status") && records.status === "success") {
                            const records = await this.getRecords();
                            this.setState({
                                deletedRecord: true,
                                records,
                                successMessage: "",
                            })
                        }
                    }
                    catch (error) {
                        await AccessDeniedUrl(error.status, this.props);
                    }
                }
            });
    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const recordsDetails = JSON.parse(localStorage.getItem('recordsDetails'));
        let successMessage = "";
        if (recordsDetails !== null && recordsDetails === "success") {
            successMessage = recordsDetails;
            localStorage.removeItem('recordsDetails')
        }
        const records = await this.getRecords();
        this.setState({ records, successMessage })
    }
    getRecords = async () => {
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const BlogService = new BlogServices();
            const records = await BlogService.getAllBlog(user.token);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            return records;
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        /*e.g. 
           {
           dataField: 'phaseName',
           order: 'desc'
       }*/
        const defaultSorted = [];
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Manage Blog"
                Addbtn={true}
                url={`/manage-blog/add`}
                Addbtntext="Add Blog">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {
                                this.state.successMessage &&
                                <Successmessage />
                            }
                            {
                                this.state.deletedRecord &&
                                <Deletedmessage />
                            }
                            <Table
                                records={this.state.records}
                                columns={this.state.columns}
                                defaultSortedChange={false}
                                defaultSorted={defaultSorted}
                            />
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default View;