import React from "react";
import { Link } from "react-router-dom";


const FourZeroFour = () => {
    return (< div className="error-page" >
        <h2 className="headline text-warning"> 401 </h2>

        <div className="error-content">
            <h3><i className="fas fa-exclamation-triangle text-warning"></i> You are not Authorized. </h3>

            <p> Sorry, You're not Authorized for access this page.Meanwhile, you may <Link to="/">return to dashboard</Link> or contact admin. </p>
        </div>
    </div>);
}
export default FourZeroFour;