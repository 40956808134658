import React from 'react';
const Select = (props) => {
    return (
        <select
            name={props.name}
            className={props.className}
            multiple={props.multiple && props.multiple ? true : false}
            onChange={props.onChange && props.onChange}
            value={props.selectedValue}
        >
            {props.placeholder &&
                <option value="" disabled>{props.placeholder}</option>
            }
            {props.options &&
                props.options.map((option, index) => {
                    return (
                        <option

                            value={option.value}
                            key={index} >{option.text}</option>);
                })
            }
        </select>);
}
export default Select;