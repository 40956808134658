const Script_append = url => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    }
};
export default Script_append;
const AccessDeniedUrl = (error, props) => {
    switch (error) {
        case "TokenExpired":
            props.tokenRemove();
            break;
        case "unauthorized":
            props.history.push("/401")
            break;
        default:
    }
};
export { AccessDeniedUrl };
