import fieldname from './fieldsname';
export default {
    required: (field, validation, args) => {
        return `This ${fieldname[field]} field is required.`
    },
    email: 'Enter valid email address',
    regex: (field, validation, args) => {
        return `The ${fieldname[field]} format is invalid.`
    },
    url: (field, validation, args) => {
        return `The ${fieldname[field]} format is invalid.`
    },
    number: (field, validation, args) => {
        return `The ${fieldname[field]} is required number.`
    },
    min: (field, validation, args) => {
        return `The ${fieldname[field]} required at least ${args} value.`
    },
    minlength: (field, validation, args) => {
        return `The ${fieldname[field]} required at least ${args} value.`
    },
    unique: (field, validation, args) => {
        return `The ${fieldname[field]} has already been taken.`
    },
    requiredWithoutField: (field, validation, args) => {
        return `The ${fieldname[field]} field is required .`
    },
}