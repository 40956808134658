import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import BlogServices from '../../../services/BlogServices';
import DropdownServices from '../../../services/DropdownServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { AccessDeniedUrl } from '../../../hooks';
import TextEditor from '../../Basecompoents/TextEditor';
import Select from '../../Basecompoents/Select';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Blog_title: "",
            Blog_content: "",
            Blog_slug: "",
            Blog_author_id: "",
            Blog_Image: "",
            Blog_category_id: "",
            Blog_is_featured: false,
            oldBlog_Image: "",
            option: 'add',
            categories: [],
            authors: [],
            id: '',
            errors: "",
            success: "",
            title: "Add"
        };

    }
    eventHandler = (content, editor) => {
        this.setState({
            Blog_content: content,
        });
    }
    onChangeEvent = (event) => {
        if (event.target.name == "Blog_Image") {
            if (event.target.files.length > 0) {
                if (event.target.files[0].size <= 10485760) {
                    let ext = event.target.files[0].name.split('.').pop().toLowerCase();
                    let ext_array = ['jpg', 'jpeg', 'png'];
                    if (ext_array.indexOf(ext) > -1) {
                        this.setState({
                            [event.target.name]: event.target.files[0],
                        })
                        document.querySelector(".custom-file-label").textContent = event.target.files[0].name;
                        let errors = {};
                        this.setState({ errors })
                    }
                    else {
                        let errors = {
                            Blog_Image: 'File extension is not valid.'
                        }
                        this.setState({ errors })
                    }
                }
                else {
                    let errors = {
                        Blog_Image: 'File size is too big.'
                    }
                    this.setState({ errors })
                }
            }

        }
        else if (event.target.name === "Blog_is_featured") {
            if (event.target.checked) {
                this.setState({
                    [event.target.name]: event.target.value,
                });
            }
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const user = JSON.parse(localStorage.getItem('user'));
        const DropdownService = new DropdownServices();
        const categories = await DropdownService.getCategoryDropdown(user.token);
        const authors = await DropdownService.getAuthorDropdown(user.token);
        if (categories.length > 0) {
            this.setState({ categories, authors })
        }
        const pathname = this.props.location.pathname.split('/')
        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {

                const BlogService = new BlogServices();
                const records = await BlogService.getDetailBlog(user.token, id);
                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const data = records.data;
                    data['oldBlog_Image'] = data.Blog_Image;
                    data.Blog_Image = "";
                    data.Blog_category_id = data.Blog_category_id.id;
                    data.Blog_author_id = data.Blog_author_id.id;
                    this.setState({ ...data, id, option, title: 'Edit' });
                }
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        }
    }
    onSubmithandler = async (event) => {
        const BlogService = new BlogServices();
        event.preventDefault();
        try {
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await BlogService.addBlog(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                Blog_content: "",
                Blog_title: "",
                Blog_slug: "",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-blog/view")

            }
        } catch (errors) {
            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} Blog`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Title: *" />
                                        <Input value={this.state.Blog_title} onChange={this.onChangeEvent} name="Blog_title" className="form-control" type="text" />
                                        {this.state.errors.Blog_title && (
                                            <small className="text-danger">
                                                {this.state.errors.Blog_title}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Slug: *" />
                                        <Input value={this.state.Blog_slug} onChange={this.onChangeEvent} name="Blog_slug" className="form-control" type="text" />
                                        {this.state.errors.Blog_slug && (
                                            <small className="text-danger">
                                                {this.state.errors.Blog_slug}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup >
                                        <Label text="Category: *" />
                                        <Select onChange={this.onChangeEvent}
                                            selectedValue={this.state.Blog_author_id ?? ""}
                                            className="form-control select2"
                                            name="Blog_author_id"
                                            placeholder="Select Author"
                                            options={this.state.authors} />
                                        {this.state.errors.Blog_author_id && (
                                            <small className="text-danger">
                                                {this.state.errors.Blog_author_id}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Content: *" />
                                        <TextEditor onChange={this.eventHandler} option={this.state.option} value={this.state.Blog_content} />
                                        {this.state.errors.Blog_content && (
                                            <small className="text-danger">
                                                {this.state.errors.Blog_content}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Image: * " for="InputFile" />
                                        <small className="text-danger"> Max file size : 10MB | allowed file type : jpg,jpeg,png</small>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <Input className="custom-file-input" onChange={this.onChangeEvent} accept="image/jpeg,image/png" name="Blog_Image" type="file" />
                                                <Label text="Image" for="InputFile" className="custom-file-label" />
                                            </div>
                                        </div>
                                        {this.state.errors.Blog_Image && (
                                            <small className="text-danger">
                                                {this.state.errors.Blog_Image}
                                            </small>
                                        )}
                                        {this.state.option == "edit" ?
                                            <Link to={`${config.UploadDir}${this.state.oldBlog_Image}`} target="_blank" >{`${config.UploadDir}${this.state.oldBlog_Image}`}</Link> : ''
                                        }
                                    </Formgroup>
                                    <Formgroup >
                                        <Label text="Category: *" />
                                        <Select onChange={this.onChangeEvent}
                                            selectedValue={this.state.Blog_category_id ?? ""}
                                            className="form-control select2"
                                            name="Blog_category_id"
                                            placeholder="Select Category"
                                            options={this.state.categories} />
                                        {this.state.errors.Blog_category_id && (
                                            <small className="text-danger">
                                                {this.state.errors.Blog_category_id}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Is Featured:" className="custom-position-label" for="checkboxPrimary2" />
                                        <div className="icheck-primary d-inline ml-2">
                                            <Input
                                                id="checkboxPrimary2"
                                                checked={this.state.Blog_is_featured == "true" || this.state.Blog_is_featured ? true : false}
                                                onChange={this.onChangeEvent}
                                                name="Blog_is_featured"
                                                type="checkbox"
                                                value="true" />
                                            <Label for="checkboxPrimary2" />
                                        </div>
                                    </Formgroup>
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-blog/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;