import { validateAll, extend } from 'indicative/validator'
import { minlength } from './../lib/customvalidation';
import messages from '../lang/validation'
import { config } from '../config'
import Axios from 'axios'

export default class RolePermissionServices {
    async getAllRolePermissions(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-userrole-permission/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getPermissionAssignedToRole(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-userrole-permission/permission-assigned-to-role`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailRolePermisson(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-userrole-permission/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getUserRoleView(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-userrole-permission/userrole/`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deleteRolePermission(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-userrole-permission/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addRolePermission(data, token) {
        extend('minlength', minlength);
        const rules = {
            rolepermissions: 'required|object|minlength:1',
            userroleID: 'required',
        }
        try {
            await validateAll(data, rules, messages)
            const { rolepermissions, userroleID, option, id } = data;
            let response;
            if (option === "add") {
                response = await Axios.post(
                    `${config.API_URL}/manage-userrole-permission/add`,
                    { rolepermissions, userroleID },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            } else {
                response = await Axios.patch(
                    `${config.API_URL}/manage-userrole-permission/edit`,
                    { rolepermissions, userroleID, id },
                    {
                        headers: {
                            'Authorization': `${token}`
                        }
                    })
            }
            if ('errors' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            const tmp_errors = {};
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
