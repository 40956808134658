import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import RolepermissionServices from '../../../services/RolepermissionServices';
import DropdownServices from '../../../services/DropdownServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import Select from '../../Basecompoents/Select';
import PermissionServices from '../../../services/PermissionServices';
import { AccessDeniedUrl } from '../../../hooks';

class Form extends Component {
    constructor(props) {
        super(props);
        this.rolePer = {};
        this.state = {
            userroleID: "",
            option: 'add',
            id: '',
            errors: "",
            success: "",
            title: "Assign",
            userroles: [],
            permissions: [],
            rolepermissions: {}
        };

    }
    onChangeEvent = (event) => {
        if (event.target.name === "rolepermissions") {
            if (event.target.checked) {
                this.rolePer[event.target.dataset.permission] = event.target.value;
                this.setState({
                    rolepermissions: { ...this.rolePer }
                });
            }
            else {

                delete this.rolePer[event.target.dataset.permission]
                this.setState({
                    rolepermissions: { ...this.rolePer }
                });
            }
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const user = JSON.parse(localStorage.getItem('user'));
        const RolepermissionService = new RolepermissionServices();
        const DropdownService = new DropdownServices();

        var assignedRole;
        var userroles;
        if (this.state.option === 'add') {
            assignedRole = await RolepermissionService.getUserRoleView(user.token);
            userroles = await DropdownService.getUserRoleDropdownExclude(user.token, assignedRole.data);
        }
        const PermissionService = new PermissionServices();
        const permissions = await PermissionService.getAllPermissionsWithGroup(user.token);
        this.setState({ userroles, permissions })

        const pathname = this.props.location.pathname.split('/')
        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {
                const records = await RolepermissionService.getDetailRolePermisson(user.token, id);
                const userroles = await DropdownService.getUserRoleDropdown(user.token, id);

                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const data = records.data;
                    this.rolePer = { ...data.rolepermissions }
                    this.setState({ ...data, id, userroleID: id, userroles, option, title: 'Edit' });
                }
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        }

        // const Checkboxs = document.querySelectorAll(".permission-menu");
        // Checkboxs.forEach((Checkbox, index) => {
        //     this.ValidateCheckbox(Checkbox);
        //     Checkbox.addEventListener('click', (event) => {
        //         this.ValidateCheckbox(event.target, true);
        //     })
        // })
    }

    ValidateCheckbox = (Checkbox, eventListener = false) => {
        let chkbxParent = Checkbox.parentElement.parentElement;
        let chkboxs = chkbxParent.querySelectorAll("input:not(.permission-menu) ");
        if (Checkbox.checked) {
            chkboxs.forEach(function (chkbox) {
                chkbox.disabled = false
            })
        } else {
            chkboxs.forEach(function (chkbox) {
                console.log(chkbox)
                console.log('------')
                chkbox.checked = false;
                chkbox.disabled = true
            })
        }
    }
    onSubmithandler = async (event) => {
        const RolepermissionService = new RolepermissionServices();
        event.preventDefault();
        try {
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await RolepermissionService.addRolePermission(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                userrole: "",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-userrole-permission/view")

            }
        } catch (errors) {
            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} Role Permission`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {
                                    (this.state.userroles.length > 0 &&
                                        this.state.permissions.length > 0) ?
                                        <form onSubmit={this.onSubmithandler}>
                                            <Formgroup>
                                                <Label text="Role: *" />
                                                <Select onChange={this.onChangeEvent}
                                                    selectedValue={this.state.userroleID}
                                                    className="form-control select2"
                                                    name="userroleID"
                                                    placeholder="Select Role"
                                                    options={this.state.userroles} />
                                                {this.state.errors.userroleID && (
                                                    <small className="text-danger">
                                                        {this.state.errors.userroleID}
                                                    </small>
                                                )}
                                            </Formgroup>
                                            {
                                                this.state.permissions &&
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Permission</th>
                                                            {
                                                                this.state.permissions[0].Permissiongroups.map((Permissiongroup, index) => {
                                                                    return <th
                                                                        key={index}
                                                                        className="text-center">{Permissiongroup.permissionTypeID.type}</th>
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.permissions.map((permission, index) => {
                                                                return (<tr key={index}>
                                                                    <td>{permission.permission}</td>
                                                                    {permission.Permissiongroups.map((Permissiongroup, index) => {
                                                                        return (<td key={index}>
                                                                            <Input
                                                                                value={Permissiongroup.id}
                                                                                type="checkbox"
                                                                                name={`rolepermissions`}
                                                                                data-permission={`${permission.permission.replace(/\s/g, "-").toLowerCase()}-${Permissiongroup.permissionTypeID.type.replace(/\s/g, "-").toLowerCase()}`}
                                                                                checked={
                                                                                    this.state.rolepermissions[`${permission.permission.replace(/\s/g, "-").toLowerCase()}-${Permissiongroup.permissionTypeID.type.replace(/\s/g, "-").toLowerCase()}`] == Permissiongroup.id ? true : false
                                                                                }
                                                                                className={`form-control permission-${Permissiongroup.permissionTypeID.type.replace(/\s/g, "-").toLowerCase()}`}
                                                                                onChange={this.onChangeEvent} />
                                                                        </td>)
                                                                    })}
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                            {this.state.errors.rolepermissions && (
                                                <small className="text-danger">
                                                    {this.state.errors.rolepermissions}
                                                </small>
                                            )}
                                            <Formgroup>
                                                <Input className="btn btn-success float-right" type="submit" value="Save" />
                                                <Link to="/manage-userrole-permission/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                                {this.state.success && (
                                                    <small className="success-text">{this.state.success}</small>
                                                )}
                                            </Formgroup>
                                        </form>
                                        :
                                        <div className="not-found-error">
                                            <p><i className="fas fa-exclamation-triangle"></i> Please create Permission or User Role</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <script>
                    {/* {window.addEventListener('load', () => {
                        alert('zzzz');
                    })} */}

                </script>
            </ContentWrapper>
        );
    }
}

export default Form;