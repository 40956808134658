import React from 'react';

const Footer = () => {
    return (

        <footer className="main-footer">
            <strong>Copyright © {process.env.CP_YEARS} <a href={process.env.SITE_URL}>{process.env.REACT_APP_TITLE}</a>.</strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 3.0.0
        </div>
        </footer>
    );
}

export default Footer;