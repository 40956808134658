import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoginRoute = ({ children, ...rest }) => {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    return (
        <Route
            {...rest}
            render={({ location }) => user == null ? (children) : (<Redirect to={{ pathname: "/", state: { from: location } }} />)}
        />
    );
}

export default LoginRoute;