import { config } from '../config'
import Axios from 'axios'

export default class CustomValidationServices {
    async uniqueValidation(data, token) {
        const { tableName, columnName, Inputvalue, id } = data;
        var response;
        try {
            if (id === undefined) {
                response = await Axios.get(`${config.API_URL}/custom-validation/unique/${tableName}/${columnName}/${Inputvalue}/`, {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
            } else {
                response = await Axios.get(`${config.API_URL}/custom-validation/unique/${tableName}/${columnName}/${Inputvalue}/${id}`, {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
            }
            if (('status' in response.data) && response.data.status == "token") {
                throw response.data;
            }
            return response.data.validate;
        }
        catch (error) {
            return error;
        }
    }
}
