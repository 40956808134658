import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../../helpers/PrivateRoute'
import LoginRoute from '../../helpers/LoginRoute'
import Routes from '../../Routes';

const CustomSwitch = ({ userDetailsUpdate, ...restprops }) => {
    return (<Switch>
        {Routes.map((SingleRoute, index) => {
            if (SingleRoute.PrivateRoute) {
                return (<PrivateRoute {...restprops} path={SingleRoute.url} key={index} exact={SingleRoute.exact}>
                    <SingleRoute.component  {...restprops} />
                </PrivateRoute>);
            }
            else if (SingleRoute.LoginRoute) {
                return (<LoginRoute path={SingleRoute.url} key={index} exact={SingleRoute.exact} >
                    <SingleRoute.component userDetailsUpdate={userDetailsUpdate} {...restprops} />
                </LoginRoute>);
            }
            else if (SingleRoute.errorFound) {
                return (<Route key={index} path={SingleRoute.url} exact={SingleRoute.exact} >
                    <SingleRoute.component  {...restprops} />
                </Route>);
            } else {
                return (<Route path={SingleRoute.url} key={index} exact={SingleRoute.exact} >
                    <SingleRoute.component  {...restprops} />
                </Route>);
            }
        })}
        <Redirect to="/404" />
    </Switch >);
}

export default CustomSwitch;

