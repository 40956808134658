import React from 'react';
import { Link } from 'react-router-dom';

const ActionButton = ({ editRecord, deleteRecord, deleteEvent, detailRecord, location, userDetails, userAllowedPermission, ...restProps }) => {
    const { pathname } = location
    const { UserPermission, isAdmin } = userDetails.loggedInUser
    const accessPermission = pathname.split("/").slice(1)[0];
    const getUserPermission = UserPermission[accessPermission];
    return (
        <div className="row action-btn-div">
            {
                (getUserPermission != undefined && getUserPermission.edit) || isAdmin ?
                    editRecord &&
                    <Link to={editRecord} className="btn btn-primary float-right mr-2">Edit</Link>
                    : ''
            }
            {
                (getUserPermission != undefined && getUserPermission.delete) || isAdmin ?
                    deleteRecord &&
                    <Link to="" data-id={deleteRecord} onClick={deleteEvent} className="btn btn-danger float-right  mr-2">Delete</Link>
                    : ''
            }
            {
                (getUserPermission != undefined && getUserPermission.detail) || isAdmin ?
                    detailRecord &&
                    <Link to={detailRecord} className="btn btn-info float-right">View</Link>
                    : ''
            }
        </div>
    );
}
export default ActionButton