import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import PhaseDetailServicesSA from '../../../services/PhaseDetailServicesSA';
import DropdownServices from '../../../services/DropdownServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import Select from '../../Basecompoents/Select';
import { AccessDeniedUrl } from '../../../hooks';
import TextEditor from '../../Basecompoents/TextEditor';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workshopDescription: "",
            workshopHours: "",
            workshopName: "",
            programPhaseID: "",
            option: 'add',
            id: '',
            errors: "",
            success: "",
            programPhaseSA: [],
            title: "Add"
        };

    }
    eventHandler = (content, editor) => {
        this.setState({
            workshopDescription: content,
        });
    }
    onChangeEvent = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const user = JSON.parse(localStorage.getItem('user'));
        const DropdownService = new DropdownServices();
        const programPhaseSA = await DropdownService.getPhaseDropdownSA(user.token);
        if (programPhaseSA.length > 0) {
            this.setState({ programPhaseSA })
        }
        const pathname = this.props.location.pathname.split('/');
        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {
                const PhaseDetailServiceSA = new PhaseDetailServicesSA();
                const records = await PhaseDetailServiceSA.getDetailPhaseDetailsSA(user.token, id);
                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const { programPhaseID, ...data } = records.data;
                    this.setState({ ...data, programPhaseID: programPhaseID.id, id, option, title: 'Edit' });
                }
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        }
    }
    onSubmithandler = async (event) => {
        const PhaseDetailServiceSA = new PhaseDetailServicesSA();
        event.preventDefault();
        try {
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await PhaseDetailServiceSA.addPhaseDetailsSA(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                WorkshopDescription: "",
                WorkshopHours: "",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-phase-details-SA/view")

            }
        } catch (errors) {
            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Add Program Phase SA" >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Workshops Name: *" />
                                        <Input value={this.state.workshopName} onChange={this.onChangeEvent} name="workshopName" className="form-control" type="text" />
                                        {this.state.errors.workshopName && (
                                            <small className="text-danger">
                                                {this.state.errors.workshopName}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Workshops Hours: *" />
                                        <Input value={this.state.workshopHours} onChange={this.onChangeEvent} name="workshopHours" className="form-control" type="text" />
                                        {this.state.errors.workshopHours && (
                                            <small className="text-danger">
                                                {this.state.errors.workshopHours}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Workshop Description: *" />
                                        <TextEditor onChange={this.eventHandler} value={this.state.workshopDescription} />
                                        {this.state.errors.workshopDescription && (
                                            <small className="text-danger">
                                                {this.state.errors.workshopDescription}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Program Phase: *" />
                                        <Select onChange={this.onChangeEvent}
                                            selectedValue={this.state.programPhaseID}
                                            className="form-control select2"
                                            name="programPhaseID"
                                            placeholder="Select Program Phase"
                                            options={this.state.programPhaseSA} />
                                        {this.state.errors.programPhaseID && (
                                            <small className="text-danger">
                                                {this.state.errors.programPhaseID}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-phase-details/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;