import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import HireDeveloperServices from '../../../services/HireDeveloperServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import Select from '../../Basecompoents/Select';
import Textarea from '../../Basecompoents/Textarea';
import { Link } from 'react-router-dom';
import { AccessDeniedUrl } from '../../../hooks';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            companyName: "",
            companySite: "",
            workEmail: "",
            workPhone: "",
            workArrangement: "",
            jobTitle: "",
            requiredSkills: "",
            MinimumEducation: "",
            applyByDate: "",
            jobDescription: "",
            errors: "",
            success: "",
        };

    }
    onChangeEvent = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    onSubmithandler = async (event) => {
        const HireDeveloperService = new HireDeveloperServices();
        event.preventDefault();
        try {
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await HireDeveloperService.addHireDeveloper(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                name: "",
                companyName: "",
                companySite: "",
                workEmail: "",
                workPhone: "",
                workArrangement: "",
                jobTitle: "",
                requiredSkills: "",
                MinimumEducation: "",
                applyByDate: "",
                jobDescription: "",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-hire-a-codegirl/view")

            }
        } catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Add Hire a Codegirls" >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Name: *" />
                                        <Input value={this.state.name} onChange={this.onChangeEvent} name="name" className="form-control" type="text" />
                                        {this.state.errors.name && (
                                            <small className="text-danger">
                                                {this.state.errors.name}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Company Name: *" />
                                        <Input value={this.state.companyName} onChange={this.onChangeEvent} name="companyName" className="form-control" type="text" />
                                        {this.state.errors.companyName && (
                                            <small className="text-danger">
                                                {this.state.errors.companyName}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Company Website: *" />
                                        <Input value={this.state.companySite} onChange={this.onChangeEvent} name="companySite" className="form-control" type="text" />
                                        {this.state.errors.companySite && (
                                            <small className="text-danger">
                                                {this.state.errors.companySite}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Work Email Address: *" />
                                        <Input value={this.state.workEmail} onChange={this.onChangeEvent} name="workEmail" className="form-control" type="text" />
                                        {this.state.errors.workEmail && (
                                            <small className="text-danger">
                                                {this.state.errors.workEmail}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Work Phone Number: *" />
                                        <Input value={this.state.workPhone} onChange={this.onChangeEvent} name="workPhone" className="form-control" type="text" />
                                        {this.state.errors.workPhone && (
                                            <small className="text-danger">
                                                {this.state.errors.workPhone}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Work Arrangement: *" />
                                        <Select onChange={this.onChangeEvent}
                                            selectedValue={this.state.workArrangement}
                                            className="form-control select2"
                                            name="workArrangement"
                                            options={[
                                                { value: "Remote", text: "Remote" },
                                                { value: "On Site", text: "On Site" },
                                            ]} />
                                        {this.state.errors.workArrangement && (
                                            <small className="text-danger">
                                                {this.state.errors.workArrangement}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Job Title: *" />
                                        <Input value={this.state.jobTitle} onChange={this.onChangeEvent} name="jobTitle" className="form-control" type="text" />
                                        {this.state.errors.jobTitle && (
                                            <small className="text-danger">
                                                {this.state.errors.jobTitle}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Required Skills: *" />
                                        <Input value={this.state.requiredSkills} onChange={this.onChangeEvent} name="requiredSkills" className="form-control" type="text" />
                                        {this.state.errors.requiredSkills && (
                                            <small className="text-danger">
                                                {this.state.errors.requiredSkills}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Minimum Education: *" />
                                        <Input value={this.state.MinimumEducation} onChange={this.onChangeEvent} name="MinimumEducation" className="form-control" type="text" />
                                        {this.state.errors.MinimumEducation && (
                                            <small className="text-danger">
                                                {this.state.errors.MinimumEducation}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Apply By Date: *" />
                                        <Input value={this.state.applyByDate} onChange={this.onChangeEvent} name="applyByDate" className="form-control" type="date" />
                                        {this.state.errors.applyByDate && (
                                            <small className="text-danger">
                                                {this.state.errors.applyByDate}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Job Description: *" />
                                        <Textarea text={this.state.jobDescription} onChange={this.onChangeEvent} name="jobDescription" className="form-control" />
                                        {this.state.errors.jobDescription && (
                                            <small className="text-danger">
                                                {this.state.errors.jobDescription}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-hire-a-codegirl/View" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;