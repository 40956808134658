import React, { Fragment } from 'react';
import { Component } from 'react';
import AuthServices from '../../../services/AuthServices';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            remember: "",
            errors: "",
        };
    }
    onChangeEvent = (event) => {
        if (event.target.name === "remember") {
            if (event.target.checked) {
                this.setState({
                    [event.target.name]: event.target.value,
                });
            }
            else {
                this.setState({
                    [event.target.name]: "",
                });
            }
        }
        else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    }
    componentWillUnmount() {
        this.setState({});
    }
    SubmitHandler = async (event) => {
        event.preventDefault();
        const authservices = new AuthServices();
        try {

            const response = await authservices.LoginAuth(this.state);
            localStorage.setItem('user', JSON.stringify(response));
            await this.props.userDetailsUpdate(response);
            this.props.history.push('/');
        }
        catch (errors) {
            this.setState({ errors });
        }
    };
    render() {

        return (
            <Fragment>
                <div className="hold-transition login-page">
                    <div className="login-box">
                        <div className="login-logo">
                            <a href="/"><b>{process.env.REACT_APP_SHORT_TITLE}</b> AdminPanel</a>
                        </div>
                        <div className="card">
                            <div className="card-body login-card-body">
                                <form onSubmit={this.SubmitHandler}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name="email"
                                                    onChange={this.onChangeEvent}
                                                />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-envelope"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.errors.email && (
                                                <small className="warning-text">
                                                    {this.state.errors.email}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    name="password"
                                                    onChange={this.onChangeEvent}
                                                />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.errors.password && (
                                                <small className="warning-text">
                                                    {this.state.errors.password}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-8">
                                            <div className="icheck-primary">
                                                <input type="checkbox" id="remember" name="remember" value="1" onChange={this.onChangeEvent} />
                                                <label htmlFor="remember"> Remember Me</label>
                                            </div>
                                        </div> */}
                                        <div className="col-4 float-right">
                                            <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                        </div>
                                    </div>
                                </form>
                                {/* <p className="mb-1">
                                    <a href="forgot-password.html">I forgot my password</a>
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Login;