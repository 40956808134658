import { validateAll, validations } from 'indicative/validator'
import messages from '../lang/validation'
import { config } from '../config'
import Axios from 'axios'

export default class HireDeveloperServices {
    async getHireDeveloper(token) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-hire-a-codegirl/view`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async getDetailHireDeveloper(token, id) {
        try {
            const response = await Axios.get(`${config.API_URL}/manage-hire-a-codegirl/detail/${id}`, {
                headers: {
                    'Authorization': `${token}`
                }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async deleteHireDeveloper(token, id) {
        try {
            const response = await Axios.delete(`${config.API_URL}/manage-hire-a-codegirl/delete`, {
                headers: {
                    'Authorization': `${token}`
                },
                data: { id }
            })
            if ('status' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (error) {
            return error;
        }
    }
    async addHireDeveloper(data, token) {
        const rules = {
            name: 'required',
            companyName: 'required',
            companySite: 'required|url',
            workEmail: 'required|email',
            workPhone: [validations.required(), validations.regex(['^[+]*[0-9]{0,2}[\s]{0,1}[-]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]{0,9}$'])],
            workArrangement: 'required',
            jobTitle: 'required',
            requiredSkills: 'required',
            MinimumEducation: 'required',
            applyByDate: 'required',
            jobDescription: 'required',
        }
        try {
            await validateAll(data, rules, messages)
            const { name, companyName, companySite, workEmail, workPhone, workArrangement, jobTitle, requiredSkills, MinimumEducation, applyByDate, jobDescription } = data;
            const response = await Axios.post(
                `${config.API_URL}/manage-hire-a-codegirl/add`,
                { name, companyName, companySite, workEmail, workPhone, workArrangement, jobTitle, requiredSkills, MinimumEducation, applyByDate, jobDescription },
                {
                    headers: {
                        'Authorization': `${token}`
                    }
                })
            if ('errors' in response.data) {
                throw response.data;
            }
            return response.data;
        }
        catch (validation_error) {
            const tmp_errors = {};
            if (!('errors' in validation_error)) {
                validation_error.forEach(function (error) {
                    tmp_errors[error.field] = error.message
                })
            }
            else {
                for (let key in validation_error.errors) {
                    tmp_errors[key] = validation_error.errors[key]
                }
            }
            throw tmp_errors;
        }
    }
}
