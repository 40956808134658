import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import GetInvolvedServices from '../../../services/GetInvolvedServices';
import Label from '../../Basecompoents/Label';
import Image from '../../Basecompoents/Image';
import { Link } from 'react-router-dom';
import { AccessDeniedUrl } from '../../../hooks';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Detail Get Involved",
            Involved_Name: "",
            Involved_Description: "",
            Involved_Url: "",
            Involved_Image: "",
        };

    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const id = await this.props.location.pathname.split('/').pop();
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const Involved = new GetInvolvedServices();
            const records = await Involved.getDetailInvolved(user.token, id);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ ...data });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Get Involved Details"
                Addbtn={true}
                url={`/manage-get-involved/view`}
                Addbtntext="Back">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Involved Name:" /> <br />
                                        {this.state.Involved_Name}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Involved Description:" /> <br />
                                        {this.state.Involved_Description}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Involved URL:" /> <br />
                                        <Link to={this.state.Involved_Url} target="_blank"> {this.state.Involved_Url}</Link>
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Image
                                            src={`${this.state.Involved_Image}`}
                                            alt={this.state.Involved_Image}
                                            style={{ width: '150px', height: '150px' }}
                                            className="img-thumbnail" />
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </ContentWrapper >
        );
    }
}

export default Detail;