import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import BrainBehindCGServices from '../../../services/BrainBehindCGServices';
import Label from '../../Basecompoents/Label';
import Image from '../../Basecompoents/Image';
import { Link } from 'react-router-dom';
import { AccessDeniedUrl } from '../../../hooks';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Detail Brain Behind CG",
            name: "",
            description: "",
            designation: "",
            subtitle: "",
            email: "",
            phoneNumber: "",
            quote: "",
            image: "",
            twitter: "",
            facebook: "",
            linkedin: "",
        };

    }
    async componentDidMount() {
        document.title = `${document.title} | ${this.state.title}`;
        const id = await this.props.location.pathname.split('/').pop();
        try {

            const user = JSON.parse(localStorage.getItem('user'));
            const BrainBehindCGService = new BrainBehindCGServices();
            const records = await BrainBehindCGService.getDetailBrainBehindCG(user.token, id);
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            if (records.hasOwnProperty("status") && records.status === "success") {
                const data = records.data;
                this.setState({ ...data });
            }
        }
        catch (error) {
            await AccessDeniedUrl(error.status, this.props);
        }
    }
    render() {
        return (
            <ContentWrapper
                {...this.props}
                pageHeading="Brain Behind CG Details"
                Addbtn={true}
                url={`/manage-brains-behind-cg/view`}
                Addbtntext="Back">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="row">
                                <div className="col-12 py-4">
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Name:" /> <br />
                                        {this.state.name}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Designation:" /> <br />
                                        {this.state.designation}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Subtitle:" /> <br />
                                        {this.state.subtitle}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Email:" /> <br />
                                        {this.state.email}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Phone Number:" /> <br />
                                        {this.state.phoneNumber}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Description:" /> <br />
                                        {this.state.description}
                                    </div>
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Label className="detail-page-view" text="Quote:" /> <br />
                                        {this.state.quote}
                                    </div>
                                    {
                                        this.state.linkedin &&
                                        <div className="col-12 px-4 pt-4 custom-detail-listing">
                                            <Label className="detail-page-view" text="LinkedIn Link:" /> <br />
                                            <Link to={this.state.linkedin} target="_blank"> {this.state.linkedin}</Link>
                                        </div>
                                    }
                                    {
                                        this.state.facebook &&
                                        <div className="col-12 px-4 pt-4 custom-detail-listing">
                                            <Label className="detail-page-view" text="Facebook Link:" /> <br />
                                            <Link to={this.state.facebook} target="_blank"> {this.state.facebook}</Link>
                                        </div>
                                    }
                                    {
                                        this.state.twitter &&
                                        <div className="col-12 px-4 pt-4 custom-detail-listing">
                                            <Label className="detail-page-view" text="Twitter Link:" /> <br />
                                            <Link to={this.state.twitter} target="_blank"> {this.state.twitter}</Link>
                                        </div>
                                    }
                                    <div className="col-12 px-4 pt-4 custom-detail-listing">
                                        <Image
                                            src={`${this.state.image}`}
                                            alt={this.state.image}
                                            style={{ width: '150px', height: '150px' }}
                                            className="img-thumbnail" />
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </ContentWrapper >
        );
    }
}

export default Detail;