import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CustomSwitch from '../Switch';
import SideMenu from '../template/SideMenu';
import Navbar from '../template/Navbar';
import Footer from '../template/Footer';
import componentPreventUrl from '../../componentPreventUrl';
import PermissionServices from '../../services/PermissionServices';
import AuthorizedPermissionServices from '../../services/AuthorizedPermissionServices';


class MainWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: "",
            menuItems: "",
            MenuPermission: "",
            userAllowedPermission: "",
        }
    }
    async componentDidMount() {
        const user = localStorage.getItem('user');

        if (user) {

            await this.userDetailsUpdate(JSON.parse(user))

        }
        if (this.state.userDetails == "") {
            this.props.history.push('/login');
        }

    }
    getPermission = async (location, userDetails) => {
        const { pathname } = location
        const { UserPermission, isAdmin } = userDetails.loggedInUser
        const accessPermission = pathname.split("/").slice(1)[0];
        const getUserPermission = UserPermission[accessPermission];
        return {
            edit: (getUserPermission != undefined && getUserPermission.edit) || isAdmin,
            add: (getUserPermission != undefined && getUserPermission.add) || isAdmin,
            detail: (getUserPermission != undefined && getUserPermission.detail) || isAdmin,
            delete: (getUserPermission != undefined && getUserPermission.delete) || isAdmin,
            view: (getUserPermission != undefined && getUserPermission.view) || isAdmin,
            menu: (getUserPermission != undefined && getUserPermission.menu) || isAdmin,

        }
    }
    getMenuItems = async () => {
        try {

            const PermissionService = new PermissionServices();
            const records = await PermissionService.getAllMenuItems();
            if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                throw records
            }
            return records;
        }
        catch (error) {
            switch (error.status) {
                case "TokenExpired":
                    this.props.tokenRemove();
                    break;
                case "unauthorized":
                    this.props.history.push("/401")
                    break;
            }
        }
    }
    userDetailsUpdate = async (userDetails) => {
        const menuItems = await this.getMenuItems();
        const AuthorizedPermissionService = new AuthorizedPermissionServices();
        const MenuPermission = await AuthorizedPermissionService.getMenuPermission();
        const userAllowedPermission = await this.getPermission(this.props.location, userDetails)
        this.setState({
            userDetails, menuItems, MenuPermission, userAllowedPermission
        })
    }
    tokenRemove = () => {
        localStorage.removeItem('user');
        this.props.history.push('/login');
    }
    render() {
        const { location } = this.props;
        return (
            this.state.userDetails !== "" ?
                <Fragment>
                    {
                        !(componentPreventUrl.indexOf(location.pathname) > -1)
                        &&
                        <Navbar {...this.props} userDetails={this.state.userDetails} />
                    }
                    {
                        !(componentPreventUrl.indexOf(location.pathname) > -1)
                        &&
                        <SideMenu {...this.props} userDetails={this.state.userDetails} MenuPermission={this.state.MenuPermission} menuItems={this.state.menuItems} />
                    }
                    <CustomSwitch {...this.props} userDetails={this.state.userDetails} tokenRemove={this.tokenRemove} userDetailsUpdate={this.userDetailsUpdate} userAllowedPermission={this.state.userAllowedPermission} />
                    {
                        !(componentPreventUrl.indexOf(location.pathname) > -1)
                        &&
                        <Footer {...this.props} />
                    }
                </Fragment>
                : (location.pathname === "/login" &&
                    <CustomSwitch {...this.props} userDetailsUpdate={this.userDetailsUpdate} />
                )
        )
    }
}
export default withRouter(MainWrapper);